<template>
  <b-sidebar
    v-if="billInfoForCheckWarranty && billInfoForCheckWarranty.billItems && billInfoForCheckWarranty.billItems.length"
    id="sidebar-backdrop"
    :title="`Chọn sản phẩm của hóa đơn #${billInfoForCheckWarranty.billNumber} để bảo hành`"
    :backdrop-variant="variant"
    backdrop
    shadow
    :visible="value"
    @hidden="closeSidebar"
    width="45%"
  >
    <div class="px-5 py-2 my-3">
      <b-list-group>
        <b-list-group-item
          v-for="billItem in billInfoForCheckWarranty.billItems"
          :key="billItem.id"
          href="#"
          @click="selectProductWarranty(billItem)"
          tag="label"
        >
          <b-row>
            <b-col cols="1">
              <b-form-checkbox
                :value="billItem"
                v-model="selectedProductWarrantyList"
              ></b-form-checkbox>
            </b-col>
            <b-col cols="8">
              <h5 class="mb-1">{{billItem.productName}}</h5>
              <p
                class="mb-1 font-size-sm font-italic text-primary"
                v-if="billItem.imeiCode && billItem.imeiCode.trim()"
              >
                IMEI: {{ billItem.imeiCode }}
              </p>
            </b-col>
            <b-col
              cols="3"
              align-self="center"
              class="d-flex justify-content-end"
            >
              <b-badge
                variant="primary"
                class="rounded-pill ml-auto"
                v-show="billItem.warrantyMonthNo"
              >Số tháng bảo hành: {{ billItem.warrantyMonthNo }}</b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>

    <template #footer>
      <b-row class="px-5 py-2">
        <b-col cols="6">
          <b-button
            block
            variant="outline-primary"
            @click="closeSidebar"
          >Hủy bỏ</b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            block
            variant="primary"
            @click="navigateToWarrantyPage"
          >Tạo hóa đơn bảo hành <b-badge
              variant="light"
              class="rounded ml-1"
              v-show="selectedProductWarrantyList.length"
            >{{ selectedProductWarrantyList.length }}</b-badge></b-button>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { showConfirmNotify } from '@/utils/sweet-alert2';

export default {
  name: 'BillListSelectWarrantyItemSideBar',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'dark',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'billInfoForCheckWarranty',
      'selectedProductWarrantyList',
      'billWarrantyForCreate',
    ]),
  },
  methods: {
    ...mapActions([
      'upsertProductWarranty',
      'fetchSalesReceiptForCheckWarranty',
      'setSelectedProductWarrantyList',
      'setSelectedBillWarrantyItem',
    ]),
    showConfirmNotify,
    closeSidebar() {
      this.setSelectedProductWarrantyList([]);
      this.$emit('input', false);
    },
    selectProductWarranty(product) {
      this.setSelectedBillWarrantyItem(product);
    },
    async navigateToWarrantyPage() {
      const content = {
        title: `Tạo bảo hành cho hóa đơn #${this.billInfoForCheckWarranty.billNumber}`,
        text: `Bạn có chắc chắn tạo bảo hành cho sản phẩm đã chọn?`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
        position: 'top',
      };
      const result = await this.showConfirmNotify(content);
      if (!result.value) return;

      //@todo: [lan] - modify after rollout
      this.$router.push({
        name: 'add-warranty-bill-v2',
        query: {
          billId: this.billInfoForCheckWarranty.billNumber,
          action: 'create',
        },
      });
    },
  },
};
</script>

<style>
</style>
